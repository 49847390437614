import React from "react";

import { Flex } from "@src/components/Boxes";
import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import SEO from "@src/components/SEO";
import GatsbyImageWrapper from "@src/components/GatsbyImageWrapper";

const Paragraph = styled("p")`
  font-size: 22px;
  margin: 0 0 11px;
  text-align: center;
  color: #fff;
`;

const AppImg = styled(GatsbyImageWrapper)`
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding: 1rem;
  width: 216px;
`;

function WebOnlyPd() {
  const data = useStaticQuery(graphql`
    {
      photo: file(name: { eq: "computer@2x" }) {
        childImageSharp {
          gatsbyImageData(width: 216, quality: 90, placeholder: NONE, layout: CONSTRAINED)
        }
      }
    }
  `);

  const { photo } = data;
  return (
    <>
      <SEO noindex={true} />
      <Flex justifyContent="center" backgroundColor="#52209f" width="100%" height="calc(100vh - 88px)">
        <Flex
          width="100%"
          maxWidth="380px"
          mx="auto"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Paragraph>
            Your new, interactive ClassDojo training presentation is waiting! <span aria-hidden={true}>🎉</span>
          </Paragraph>

          <AppImg image={photo} alt="Computer monitor drawing" />

          <Paragraph>
            Log in to the website from a computer/laptop to take a look <span aria-hidden={true}>😊</span>
          </Paragraph>
        </Flex>
      </Flex>
    </>
  );
}

export default WebOnlyPd;
